import clsx from "clsx"
import _ from "lodash"
import React from "react"
import Slider from "react-slick"
import "./CustomSlider.scss"

const SIZES = {
  sm: {
    mobile: 1,
    tablet: 2,
    laptop: 3,
    desktop: 3,
  },
  md: {
    mobile: 1,
    tablet: 2,
    laptop: 3,
    desktop: 4,
  },
  lg: {
    mobile: 2,
    tablet: 4,
    laptop: 4,
    desktop: 5,
  },
  xl: {
    mobile: 2,
    tablet: 4,
    laptop: 5,
    desktop: 6,
  },
}

export const CustomSlider = ({ children, className, size, darkMode,length }) => {
  const variant = _.isEmpty(size) ? SIZES.sm : SIZES[size]

  const NextArrow=({onClick})=>{
    return(
        <div onClick={onClick} className="next-arrow-button arw-show" >
        <i className="icon slider-arrow right"/>
        </div>
    )
  }

  const PrevArrow=({onClick})=>{
    return(
        <div onClick={onClick} className="prev-arrow-button arw-show">
          <i className="icon slider-arrow left"/>
        </div>
    )
  }

  const options = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: variant.desktop,
    slidesToScroll: 1,
    centerMode: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: variant.desktop,
          slidesToScroll: 1,
          infinite: length>4?true:false,
          dots: true,
          arrows:true
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: variant.laptop,
          slidesToScroll: 1,
          infinite: length>4?true:false,
          dots: true,
          arrows:true
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: variant.tablet,
          slidesToScroll: 1,
          dots: true,
          arrows:false,
          infinite: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: variant.mobile,
          slidesToScroll: 1,
          dots: true,
          arrows:true,
          infinite: false,
        },
      },
    ],
  }

  return (
    <Slider
      className={clsx(
        "custom-slider",
        { "custom-slider-dark": darkMode },
        className
      )}
      {...options}
    >
      {children}
    </Slider>
  )
}

export default CustomSlider
